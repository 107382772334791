// App.js

import React, { createContext, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { theme } from './common/theme'
import { GlobalStyles } from './components/Builders'
import { List } from './components/List'
import { ListByIngredient } from './components/ListByIngredient'
import { BrowserFingerprintCanvas } from './components/BrowserFingerprintCanvas'
import { Main } from './components/Main'
import { Navbar } from './components/Navbar'
import { Recipe } from './components/Recipe'
import { Search } from './components/Search'
import { useLocalStorage } from './hooks/useLocalStorage'

export const RecipeContext = createContext(null)

function App() {

    const [ recipeTitles,       setRecipeTitles ]       = useState({}) /* includes slugs */
    // const [ browserFingerprint, setBrowserFingerprint ] = useState()
    const [ browserFingerprint, setBrowserFingerprint ] = useLocalStorage('_bfp', '')
    const [ factorBtn,          setFactorBtn ]          = useLocalStorage('factorBtn', false)
    const [ storedFactor,       setStoredFactor ]       = useLocalStorage('storedFactor', '')

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <RecipeContext.Provider
                value={{
                    recipeTitles, setRecipeTitles,
                    factorBtn, setFactorBtn,
                    storedFactor, setStoredFactor,
                    browserFingerprint, setBrowserFingerprint
                }}
            >
                <main>
                    <Routes>
                        <Route path='/' element={ <Main /> } />
                        <Route path='/search' element={ <Search /> } />
                        <Route path='/list' element={ <List /> } />
                        <Route path='/list-by-ingredient' element={ <ListByIngredient /> } />
                        <Route
                            path='/recipe/:id'
                            element={<Recipe />}
                        />
                        <Route
                            path='/:slug'
                            element={<Recipe />}
                        />
                    </Routes>
                </main>
                <footer className='print-only'>
                    <p>URL: {window.location.href}</p>
                    <p>Printed: {(new Date()).toLocaleString()}</p>
                </footer>
                
                {/* place here to ensure it appears on top of the stack */}
                <Navbar />

                <BrowserFingerprintCanvas />

            </RecipeContext.Provider>
        </ThemeProvider>
    )
}

export default App
