// components/SortableTitles.js

import React from 'react'
import { Link } from 'react-router-dom'
import { RecipeLI, RecipeUL } from './Builders'
import { DEBUG } from '../common/constants'
import usePrevNextStruct from '../hooks/usePrevNextStruct'

export const SortableTitles = props => {

    const {
        data, // array of { _id, title, slug }
    } = props

    const prevNext = usePrevNextStruct(data)

    return (
        <RecipeUL className={DEBUG ? 'RecipeUL' : ''}>
        {
            data
            .sort((a, b) => a.title < b.title ? -1 : 1)
            .map(({ _id, title, slug }, i) => (
                <RecipeLI key={`title-${i}`} className={DEBUG ? 'RecipeLI' : ''}>
                    <Link
                        to={`/${slug}`}
                        state={{
                            prev: prevNext?.[_id] ? prevNext?.[_id][0] : null,
                            next: prevNext?.[_id] ? prevNext?.[_id][1] : null,
                        }}
                    >
                        {title}
                    </Link>
                </RecipeLI>
            ))
        }
        </RecipeUL>
    )
}
