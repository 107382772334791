// usePrevNextStruct.js

import { useEffect, useState } from 'react'

const usePrevNextStruct = data => {

    const [ prevNext, setPrevNext ] = useState({}) /* { [id]: [ prev, next ] } */

    useEffect(() => {
        /* create a struct { [id]: [ prev, next ] } */
        if (Array.isArray(data)) {
            const _ = data.reduce((acc, curr, i, _self) => {
                const collect = curr?._id ? { [curr?._id]: [ i > 0 ? _self[i-1]?.slug : null, _self[i+1]?.slug ] } : null
                return { ...acc, ...collect }
            }, {})
            // console.log(23, _)
            setPrevNext({..._})
        }
    }, [data])

    // return data ? prevNext : null
    return prevNext || {}
}

export default usePrevNextStruct
