// functions.js

import axios from 'axios'
import { ApiEndpoint, axiosHeaders } from '../common/constants'

export const sleep = ms => new Promise(resolve => 
    setTimeout(resolve, ms ?? 1000))

export const stripTags = (text = '') => text.replace(/<\/?[^>]+(>|$)/g, '')

export const dbSearch = async searchText => {
    const { urlSearch } = ApiEndpoint
    try {
        const data = await axios.post(
            urlSearch,
            { q: searchText.trim() },
            axiosHeaders
        )
            .then(({data}) => data)
        const { result } = data
        return result
    }
    catch (err) { console.error(25, { err }) }
    return false
}

export const dbGetRecipes = async () => {
    const { urlRecipes } = ApiEndpoint
    try {
        const data = await axios.get(
                urlRecipes,
                axiosHeaders
            )
            .then(({data}) => data)
        // console.log(35, data)
        return data
    }
    catch (err) { console.error(19, { err }) }
    return false
}

// export const dbGetRecipe = async recipeId => {
export const dbGetRecipeById = async recipeId => {
    const { urlRecipeById } = ApiEndpoint
    try {
        const data = await axios.get(
                `${urlRecipeById}/${recipeId}`,
                axiosHeaders
            )
            .then(({data}) => data)
        // console.log(50, data)
        const { result } = data
        return result
    }
    catch (err) { console.error(19, { err }) }
}

export const dbGetRecipeBySlug = async slug => {
    const { urlRecipeBySlug } = ApiEndpoint
    try {
        const data = await axios.get(
                `${urlRecipeBySlug}/${slug}`,
                axiosHeaders
            )
            .then(({data}) => data)
        // console.log(50, data)
        const { result } = data
        return result
    }
    catch (err) { console.error(19, { err }) }
}

export const dbGetRecipesByIngredient = async () => {
    const { urlByIngred } = ApiEndpoint
    try {
        const data = await axios.get(
            urlByIngred,
            axiosHeaders
        )
            .then(({data}) => data)
        // console.log(66, data)
        let { result } = data || {}
        if (result) {
            result = Object
                .keys(result)
                .map(k => ([k, result[k]]))
                .sort((a, b) => a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1)
        }
        return result
    }
    catch (err) { console.error(19, { err }) }
    return false
}

export const dbLog = async obj => {
    const { urlLog } = ApiEndpoint
    const paramStr = typeof obj === 'object'
        ? Object.keys(obj).reduce((acc, curr) => `${acc}/${curr}/${obj[curr]}`, '')
        : ''
    const dataUrl = `${urlLog}${paramStr}`
    // console.log(101, {dataUrl})
    try {
        const data = await axios.get(
            dataUrl,
            axiosHeaders
        )
            .then(({data}) => data)
        // console.log(103, data)
    }
    catch (err) { console.error(105, { err }) }
}

export const rndStr = () => (
    (new Date()).getTime().toString(36) 
    + '-' 
    + (Math.round(Math.random()*Math.pow(10,16))).toString(36)
)

/* if the "extra" value isn't bracketted, preceed with a comma */
export const reformatExtra = (str = '') => {
    const r = str.trim().match(/^\((.+)\)$/)
    //if (r === null) return str
    // return r === null || r.length < 4 ? `, ${str}` : r[0]
    // return r && r.length > 1 ? r[0] : `, ${str}`
    return r && r.length > 1 ? `\u00a0${r[0]}` : `, ${str}`
}

export const decimaliseFraction = str => {
    const n = typeof str === 'string' ? str.trim() : null
    if (!n) return undefined
    if (!n.search('-') < 0) return null /* no negative values allowed */
    if (!n.search('/')) return !isNaN(parseFloat(n)) ? parseFloat(n) : null
    if (isNaN(parseFloat(n))) return null
    const arr = n.split(/\s+/) /* split on any spaces */
    return arr.reduce((acc, curr, i) => {
        const [ a, b, ] = curr.split('/') /* split on any '/' */
        //return b && !isNaN(a/b) ? (a/b) + acc : acc
        return !b 
            ? (acc || 0) + parseFloat(curr)
            : !isNaN(a/b) 
                ? (a/b) + acc
                : acc
    }, null)
}

