// constants.js

/* if running development locally, use IP address to allow intra-network access */
/* defaults to 127.0.0.2 */
/* cf https://stackoverflow.com/a/8440736/23517902 */
/* NB: THIS DOESN'T WITHOUT POLYFILLS, cf https://stackoverflow.com/a/70485253/23517902 */
// const findOwnIp = () => {
//     const { networkInterfaces } = require('os')
//     const arr =  Object.keys(nets)
//         .filter(key => key !== 'lo')
//         .map(key => nets[key].find(e => e?.family === 'IPv4' && e?.internal === false))
//     const ip_address = (arr.length && arr[0]?.address) ? arr[0].address : '127.0.0.2'
//     return ip_address
// }

export const DEBUG = process.env.NODE_ENV === 'development' /* detect if in development mode */
    ? {
        debug: true,
        // ip_address: findOwnIp(),
        // ip_address: '192.168.1.201', /* hardcoded because polyfills not configured for os.networkInterfaces */
        ip_address: '127.0.0.1',
    }
    : null /* not development so nothing defined */

export const API_TOKEN = process.env.REACT_APP_API_TOKEN

// export const baseApiUrl = 'https://recipes.greyling.info/api/v2'
// export const baseApiUrl = 'https://recipeapi.greyling.tech/api/v2'
// export const baseApiUrl = 'http://localhost:3578/api/v2'
export const baseApiUrl = process.env.NODE_ENV === 'development'
    // ? 'http://localhost:3578/api/v2'
    ? `http://${DEBUG?.ip_address || '127.0.0.3'}:3578/api/v2`
    : process.env.NODE_ENV === 'production'
        ? 'https://recipeapi.greyling.tech/api/v2'
        : null

export const ApiEndpoint = {
    urlRecipes: `${baseApiUrl}/recipes`,
    urlRecipeById: `${baseApiUrl}/recipe-by-id`,
    urlRecipeBySlug: `${baseApiUrl}/recipe-by-slug`,
    urlByIngred: `${baseApiUrl}/by-ingredient`,
    urlSearch: `${baseApiUrl}/search`,
    urlLog: `${baseApiUrl}/log`,
}

export const axiosHeaders = {
    headers: {
      Authorization: `Basic ${API_TOKEN}`
    }
}

export const breakpoints = {
    xs: 376,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1920,
}
