// hooks/useLocalStorage.js

/* cf https://www.geeksforgeeks.org/reactjs-uselocalstorage-custom-hook/ */

import { useState } from 'react'

export const useLocalStorage = (keyName, defaultValue) => {
    // console.log(8, {keyName, defaultValue})
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const value = localStorage.getItem(keyName)
            if (value) {
                return JSON.parse(value)
            }
            localStorage.setItem(keyName, JSON.stringify(defaultValue))
            return defaultValue
        }
        catch (err) {
            return defaultValue
        }
    })
    // const setValue = newValue => {
    //     try {
    //         window.localStorage.setItem(keyName, JSON.stringify(newValue))
    //     }
    //     catch (err) {
    //         console.error(err)
    //     }
    //     setStoredValue(newValue)
    // }
    const setValue = valueOrFn => {
        let newValue
        if (typeof valueOrFn === 'function') {
            const fn = valueOrFn
            newValue = fn(storedValue)
        }
        else {
            newValue = valueOrFn
        }
        // console.log(40, {keyName, newValue})
        localStorage.setItem(keyName, JSON.stringify(newValue))
        setStoredValue(newValue)
    }
    return [storedValue, setValue]
}
