// List.js

import React, { useCallback, useEffect, useState, useContext } from 'react'
// import { sleep } from '../common/functions'
import { SortableTitles } from './SortableTitles'
import { RecipeContext } from '../App'
import { MainHeader } from './Builders'
import { dbGetRecipes } from '../common/functions'

export const List = () => {

    const [ loaded, setLoaded ] = useState(false)
    const [ backendErr, setBackendErr ] = useState(false)

    const {
        recipeTitles,
        setRecipeTitles,
    } = useContext(RecipeContext)

    useEffect(() => {
        document.title = 'LIST - Recipe Collection'
    }, [])

    /* TODO: refactor this */
    /* get titles and slugs of all recipes */
    const getRecipeTitles = useCallback(async () => {
        // await sleep(3000)
        const data = await dbGetRecipes()
        if (typeof data === 'boolean' && data === false) {
            setLoaded(true)
            return setBackendErr(true)
        }
        setRecipeTitles(data || [])
        setLoaded(true)
    }, [setRecipeTitles])

    useEffect(() => {
        getRecipeTitles()
    }, [getRecipeTitles]) /* NB dependency must be the const function */

    return (
        <>
            {
                loaded === false
                    ? <p>loading ...</p>
                    : backendErr
                        ? (<>
                            <p>Uh oh.... </p>
                            <p>It looks like the database is down!</p>
                            <h1>😡</h1>
                        </>)
                        : (
                            <>
                                <MainHeader>Recipes</MainHeader>
                                <SortableTitles data={recipeTitles} />
                                {/* <pre>{JSON.stringify(recipeTitles, null, 2)}</pre> */}
                            </>
                        )
            }
        </>
    )
}
